
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { Tabs } from '../../../utilities/Types/navigation.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import axios from 'axios';
import router from '@/router';
import { Role } from '@/enums/Role';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    const response = await this.fetchMalls();
    this.total = response?.total;
    this.totalMalls = response?.total;
    const requests = await this.fetchMallChangeRequests({
      page: 1,
      limit: 10000,
      search: this.searchFilter,
      sortKey: 'changeRequestId',
      sortDirection: this.sortDefinition.direction,
    });
    this.pending = 0;
    requests?.data?.forEach((request: any) => {
      if (request?.statusDesc === 'Pending') {
        this.pending++;
      }
    });

    if (this.$route.query.mallId) {
      this.updateModalComponent('mall');
      this.updateModalType('large');
      this.updateModalData({ mallId: this.$route.query.mallId, mode: 'edit' });
      this.openModal();
    }
  },
  watch: {
    loadingMalls: {
      handler() {
        this.loading = this.loadingMalls;
      },
    },
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const response = await this.fetchMalls();
          this.total = response?.total;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const response = await this.fetchMalls();
        this.total = response?.total;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const response = await this.fetchMalls();
        this.total = response?.total;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const response = await this.fetchMalls();
        this.total = response?.total;
      },
    },
  },
  computed: {
    ...mapGetters(['allCommunicationReports', 'malls', 'mallTypes', 'loadingMalls', 'user', 'access', 'permissions']),
    isLoading(): boolean {
      return this.loading || this.loadingMalls;
    },
    showSearch(): boolean {
      return true;
      // return this.access && this.access?.mallsAll;
    },
    columnDefinitions(): ColumnDefinition[] {
      if (this.permissions && this.permissions?.superAdminAccess) {
        return [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'mallOwnerDesc', // reformating on entry
            displayName: 'Owner',
          },
          {
            type: 'text',
            columnName: 'description',
            displayName: 'Mall',
            sortable: true,
          },
          {
            type: 'address',
            columnName: 'mallAddress', // reformating on entry
            displayName: 'Address',
            headerStyle: 'min-width: 200px;',
          },
          {
            type: 'text',
            columnName: 'supportsMarketing',
            displayName: 'Supports Marketing',
          },
          {
            type: 'stringArray',
            columnName: 'languageList',
            displayName: 'Language',
          },
          {
            type: 'stringArray',
            columnName: 'communicationTypeList',
            displayName: 'Communication Types',
          },
          {
            type: 'website',
            columnName: 'promoUrl',
            displayName: 'Website',
          },
          {
            type: 'url',
            columnName: 'mallEditUrl', // adding on entry
            displayName: 'Mall info Edit',
          },
          {
            type: 'contact',
            columnName: 'localContact', // reformating on entry
            displayName: 'Local Contact',
          },
          {
            type: 'stringArray',
            columnName: 'otherContacts', // reformating on entry
            displayName: 'Other Contacts',
          },
          {
            type: 'text',
            columnName: 'mallId',
            displayName: 'Mall ID',
          },
        ];
      }
      return [
        {
          type: 'text',
          columnName: 'id',
          displayName: 'ID',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'mallOwnerDesc', // reformating on entry
          displayName: 'Owner',
        },
        {
          type: 'text',
          columnName: 'description',
          displayName: 'Mall',
          sortable: true,
        },
        {
          type: 'address',
          columnName: 'mallAddress', // reformating on entry
          displayName: 'Address',
          headerStyle: 'min-width: 200px;',
        },
        {
          type: 'text',
          columnName: 'supportsMarketing',
          displayName: 'Supports Marketing',
        },
        {
          type: 'stringArray',
          columnName: 'languageList',
          displayName: 'Language',
        },
        {
          type: 'stringArray',
          columnName: 'communicationTypeList',
          displayName: 'Communication Types',
        },
        {
          type: 'website',
          columnName: 'promoUrl',
          displayName: 'Website',
        },
        {
          type: 'contact',
          columnName: 'localContact', // reformating on entry
          displayName: 'Local Contact',
        },
        {
          type: 'stringArray',
          columnName: 'otherContacts', // reformating on entry
          displayName: 'Other Contacts',
        },
      ];
    },
    tabs(): Tabs {
      if (this.permissions && this.permissions?.superAdminAccess) {
        return [
          {
            title: 'MALLS',
            route: 'view',
            activeRouteCheck: '/malls/view',
          },
          {
            title: `CHANGE REQUESTS [${this.pending || 0}]`,
            route: 'change-requests',
            activeRouteCheck: '/malls/change-requests',
          },
        ];
      } else {
        return [
          {
            title: 'MALLS',
            route: 'view',
            activeRouteCheck: '/malls/view',
          },
        ];
      }
    },
    tableData(): unknown {
      return this.malls;
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    totalMalls: number;
    pending: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: 'active' },
        { description: 'Upcoming', value: 'upcoming' },
        { description: 'Expired', value: 'expired' },
        { description: 'Draft', value: 'draft' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      totalMalls: 0,
      pending: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'fetchMalls',
      'fetchMallChangeRequests',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'resetPaginationState',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageReset = true;
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      this.searchFilter = searchFilter;
      this.resetPageValue();
    },
    create(): void {
      this.updateModalComponent('mall');
      this.updateModalType('large');
      this.openModal();
    },
    reports(): void {
      this.$router.push('/reports');
    },
    async export(): Promise<void> {
      this.buttonFeedback.exportSubmitted = true;
      this.buttonFeedback.exportLoading = false;
      const URL = `${process.env.VUE_APP_URI}/exports/malls`;
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      await axios.get(URL, {
        withCredentials: true,
      });
    },
    async rowClicked(row: any): Promise<void> {
      if (this.user.role?.roleId === Role.MallAdmin) {
        const mallLink = `${window.location.protocol}//${window.location.host}/review/mall-details?mallId=${row?.mallId}`;
        window.open(mallLink, '_blank');
      } else {
        this.updateModalComponent('mall');
        this.updateModalType('large');
        this.updateModalData({ mallId: row.mallId, mode: 'edit' });
        this.openModal();
        router.replace({ query: { mallId: row?.mallId } });
      }
    },
  },
});
